import React from 'react';
import { LandingPageIndex } from '../modules/landing-pages/components';
import { Footer, Header } from '../components/';
import { GetServerSideProps, NextPage } from 'next';
import { NextSeo } from 'next-seo';
import CookieBanner from '../components/common/cookie-banner';
import { authUserChecker } from '../modules/auth';
import { withSSRContext } from 'aws-amplify';

// Random change to test CI
const Index: NextPage = () => {

  return (
    <React.Fragment>
      <NextSeo
        title={'Enhances your local shopping experience on Coresine'}
        description={'Coresine connects businesses and customers in their local area by providing a comprehensive business-consumer solution'}
        canonical={'https://www.coresine.com'}
        openGraph={{
          title: 'Enhances your local shopping experience on Coresine',
          description: 'Coresine connects businesses and customers in their local area by providing a comprehensive business-consumer solution',
        }}
      />
      <Header forLandingPage={true} />
      <LandingPageIndex />
      <CookieBanner />
      <Footer />
    </React.Fragment>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const SSR = withSSRContext(context);
  try {
    const currentUser = await authUserChecker(SSR.Auth);
    if ( currentUser ) {
      return {
        redirect: {
          destination: '/home',
          permanent: false,
        },
      }
    } else {
      return {
        props: {},
      }
    }
  } catch (e) {
    console.error(e);
    return {
      props: {},
    }
  }
}

export default Index;
