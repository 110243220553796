import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { Dialog, Drawer, Intent } from '@blueprintjs/core';
import { useTheme } from 'emotion-theming';
import Theme from '../../../utils/theming/theme-type';
import { useTranslation } from '../../../utils/i18n/i18n';
/**
 * I had to use a className in order to change portal container's css properties
 */
import style from './style.module.scss';
import UniversalCookiesManager from '../../../utils/cookies/UniversalCookiesManager';
import CookieSettings from '../cookie-settings';
import CookieDocument from './cookie-document';
import { Button } from '@components/ui';

const CookieBanner = () => {
    const theme: Theme = useTheme();
    const [showCookieBanner, setShowCookieBanner] = useState<boolean>(false);
    const [showCookieSettings, setShowCookieSettings] = useState<boolean>(false);
    const [isCookieDocumentVisible, setIsCookieDocumentVisible] = useState<boolean>(false);
    const { t } = useTranslation();

    const universalCookieManager = new UniversalCookiesManager();

    useEffect(() => {
        const areCookiesAccepted = universalCookieManager.getLanguageAndBasketCookies();
        if (!areCookiesAccepted) {
            setTimeout(() => {
                setShowCookieBanner(true);
                /**
                 * Default timer value in milliseconds. Put there to draw more attention to the cookie drawer
                 */
            }, 2500);
        }
        if (areCookiesAccepted) {
            setShowCookieBanner(false);
        }
    }, [universalCookieManager.getLanguageAndBasketCookies()]);

    const cookieSettingsShowHandler = () => {
        setShowCookieSettings(true);
    };

    const cookieSettingsRemoveHandler = () => {
        setShowCookieSettings(false);
    };

    const acceptHandler = () => {
        setShowCookieBanner(false);
        universalCookieManager.acceptAllCookies();
    };

    const cookieDocumentCloser = (): void => {
        setIsCookieDocumentVisible(false);
    };

    const cookieDocumentOpener = (): void => {
        setIsCookieDocumentVisible(true);
    };

    return (
        <>
            <Dialog
                isOpen={showCookieSettings}
                title={t('common:cookie-settings.title')}
                onClose={cookieSettingsRemoveHandler}
                css={css`
                    background-color: white;
                `}>
                <CookieSettings
                    cookieSettingsRemoveHandler={cookieSettingsRemoveHandler}
                    setShowCookieBanner={setShowCookieBanner}
                />
            </Dialog>
            <Dialog
                isOpen={isCookieDocumentVisible}
                title={'Cookie document'}
                onClose={cookieDocumentCloser}
                lazy={true}
                css={css`
                    width: 35rem;
                `}>
                <CookieDocument />
            </Dialog>
            <Drawer
                size={'auto'}
                isOpen={showCookieBanner}
                onClose={acceptHandler}
                canOutsideClickClose={false}
                portalClassName={style['portalClassName']}
                enforceFocus={false}
                autoFocus={false}
                usePortal={true}
                hasBackdrop={false}
                position={'bottom'}
                lazy={true}>
                <div
                    css={css`
                        padding: 1rem 1.25rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    `}>
                    <div>
                        <p>
                            {t('common:cookie-banner.cookie-banner-body-text')}
                            <Button
                                text={t('common:cookie-banner.cookie-banner-cookie-policy')}
                                minimal={true}
                                onClick={cookieDocumentOpener}
                                intent={Intent.PRIMARY}
                            />
                        </p>
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: flex-start;
                            ${theme.layout.mq[1]} {
                                flex-direction: row;
                                justify-content: flex-end;
                                align-items: flex-start;
                            }
                        `}>
                        <Button
                            text={t('common:cookie-banner.cookie-banner-edit-button-text')}
                            outlined={true}
                            intent={'primary'}
                            alignText={'center'}
                            onClick={cookieSettingsShowHandler}
                            css={css`
                                margin-bottom: 0.5rem;
                                ${theme.layout.mq[1]} {
                                    margin-right: 0.5rem;
                                }
                            `}
                        />
                        <Button
                            text={t('common:cookie-banner.cookie-banner-accept-button-text')}
                            outlined={false}
                            intent={'primary'}
                            alignText={'center'}
                            onClick={acceptHandler}
                        />
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default CookieBanner;
