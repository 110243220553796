import LandingPageIndex from './landing-page-index';
import ForPartnersPageIndex from './for-partners-page-index';
import ForCustomersPageIndex from './for-customers-page-index';
import ContactUsPageIndex from './contact-us-page-index';
import AboutUsPageIndex from './about-us-page-index';
import MessagePageIndex from './message-page-index';
import reducer from '../reducers';

export {
    LandingPageIndex,
    AboutUsPageIndex,
    ContactUsPageIndex,
    ForCustomersPageIndex,
    MessagePageIndex,
    ForPartnersPageIndex
};

export { reducer };
